<template>
	<div id="group">
		<Datatable ref="datatable" v-bind:table="table" v-on:add="addGroup" v-on:export="exportGroup"
			v-on:update="updateGroup" v-on:delete="deleteGroup" v-on:addContact="tambahContact" />

		<v-dialog v-model="dialog.show" max-width="500">
			<v-card>
				<v-card-title style="background-color:#4CAF50;">
					<span class="text-h6 white--text">{{ this.dialog.title }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<v-text-field v-model="form.group_name" dense :error-messages="validation.group_name"
									color="green" outlined label="Nama Group*" required></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-row align="center" justify="end" class="mb-5 mr-5">
						<v-btn text outlined @click="dialog.show = false" class="mr-2">
							Close
						</v-btn>
						<v-btn color="green accent-3" dark @click="saveGroup()">
							Save
						</v-btn>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="contactAdd.show" max-width="1000" @click:outside="closeAddContect()">
			<v-card>
				<v-card-title style="background-color:#4CAF50;">
					<span class="text-h6 white--text">{{ this.contactAdd.title }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row align="center">
							<v-col cols="4">
								<v-text-field hide-details="auto" dense v-model="form.group_name" color="green" outlined
									label="Nama Grup" readonly></v-text-field>
							</v-col>

							<v-text-field v-if="false" v-model="formContact.group_privilege_group_id" color="green"
								outlined label="Grup_id"></v-text-field>
							<v-col cols="4">
								<v-select hide-details="auto" dense :items="contact"
									:error-messages="validation.group_privilege_contact_id" item-text="contact_name"
									item-value="contact_id" outlined color="green"
									v-model="formContact.group_privilege_contact_id" label="Pilih Kontak">
								</v-select>
							</v-col>
							<v-col cols="4">
								<v-btn color="success" @click="addGroupContact()">
									Save
								</v-btn>
							</v-col>
							<v-col cols="12" v-if="tableContact">
								<Datatable ref="datatable" v-bind:table="tableContact"
									v-on:deleteContact="deleteContact" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn outlined text @click="closeAddContect()">
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Datatable from '@/components/Datatable'
export default {
	name: 'Group',
	components: {
		Datatable
	},
	data() {
		return {
			alert: false,
			alertMessage: '',
			dialog: {
				show: false,
				title: '',
				action: '',
			},
			contactAdd: {
				show: false,
				title: '',
				action: '',
			},

			tableContact: {
				url: '',
				keys: 'group_privilege_id',
				height: '250px',
				limit: 10,
				config: [{
					text: 'Aksi',
					class: 'text-center',
					action: true,
					width: 100,
					value: [{
						icon: 'mdi-delete-empty-outline',
						class: '',
						color: 'error',
						action: 'deleteContact',
					}],
				},
				{
					text: 'Nama Kontak',
					class: 'text-center',
					value: 'contact_name',
				},
				],
			},

			table: {
				url: '/api/v1/group',
				keys: 'group_id',
				height: '250px',
				limit: 10,
				right: [{
					icon: 'mdi-plus',
					title: '',
					class: 'mr-2',
					color: '#00E676',
					action: 'add',
				},],
				config: [{
					text: 'Aksi',
					class: 'text-center',
					action: true,
					width: 200,
					value: [{
						icon: 'mdi-pencil',
						class: '',
						color: 'warning',
						action: 'update',
					},
					{
						icon: 'mdi-delete',
						class: '',
						color: 'error',
						action: 'delete',
					},
					{
						icon: 'mdi-account-multiple-plus',
						class: '',
						color: 'info',
						action: 'addContact',
					}
					],
				},
				{
					text: 'Nama Group',
					class: 'text-center',
					value: 'group_name',
				},
				],
				filter: [{
					label: 'Nama Group',
					type: 'text',
					value: 'group_name'
				},],
			},
		}
	},

	computed: {
		form() {
			return this.$store.state.group.form
		},
		formContact() {
			return this.$store.state.group.formContact
		},
		contact() {
			return this.$store.state.contact.list
		},
		validation() {
			return this.$store.state.validation.data
		}
	},
	mounted() {
		this.$refs.datatable.fetchDataTable();
		this.$store.dispatch('getListContact')
	},

	methods: {
		async saveGroup() {
			this.resetValidation();
			let action = this.dialog.action == 'save' ? 'createGroup' : 'updateGroup';
			await this.$store.dispatch(action, this.$store.state.group.form).then(res => {
				if (res) {
					this.$swal.fire({
						icon: 'success',
						title: 'Berhasil ' + this.dialog.action + ' group!!',
						showConfirmButton: false,
						timer: 1500
					})
					this.dialog.show = false
				}
				this.$refs.datatable.fetchDataTable()
			})
		},

		updateGroup(value) {
			this.resetValidation();
			this.$store.dispatch('getGroupById', {
				id: value
			})
			this.dialog.show = true;
			this.dialog.title = "Update Grup";
			this.dialog.action = 'update';
		},

		addGroup() {
			this.resetValidation();
			this.$store.state.group.form = {
				group_name: ''
			},
				this.dialog.show = true;
			this.dialog.title = "Tambah Grup";
			this.dialog.action = 'save';
		},

		exportGroup() { },

		deleteContact(value) {
			this.$swal.fire({
				title: 'Are you sure?',
				text: "This process can't be cancelled!",
				icon: 'question',
				confirmButtonColor: '#d33',
				confirmButtonText: 'Ya, Hapus!',
				showCancelButton: true,
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('deleteContactGroup', {
						group_privilege_id: value
					})
					await this.$swal.fire({
						icon: 'success',
						title: 'Success deleting group',
						showConfirmButton: false,
						timer: 1500
					})
					await this.$refs.datatable.fetchDataTable();
				}
			})
		},

		closeAddContect() {
			this.contactAdd.show = false
			this.tableContact.url = ''
			this.$store.state.group.formContact.group_privilege_contact_id = ''
			this.alert = false
		},

		addGroupContact() {
			this.alert = false
			this.resetValidation();
			this.$store.dispatch('addContactGroup', this.$store.state.group.formContact).then(res => {
				if (res) {
					this.$swal.fire({
						icon: 'success',
						title: 'Berhasil tambah kontak!!',
						showConfirmButton: false,
						timer: 1500
					})
					this.$refs.datatable.fetchDataTable();
				}
			})
		},

		tambahContact(value) {
			this.resetValidation();
			this.$store.dispatch('getGroupById', {
				id: value
			})
			this.$store.state.group.formContact.group_privilege_group_id = value
			this.tableContact.url = `/api/v1/group/detailContact?group_privilege_group_id=${value}`
			this.$refs.datatable.fetchDataTable();
			this.contactAdd.title = "Tambah Kontak Grup";
			this.contactAdd.show = true
			this.contactAdd.action = 'save';
		},

		deleteGroup(value) {
			this.$swal.fire({
				title: 'Are you sure?',
				text: "This process can't be cancelled!",
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				confirmButtonText: 'Yes, Deleted!'
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('deleteGroup', {
						group_id: value
					})
					this.$swal.fire({
						icon: 'success',
						title: 'Success deleting group!!',
						showConfirmButton: false,
						timer: 1500
					})
					await this.$refs.datatable.fetchDataTable();
				}
			})
		},
		resetValidation() {
			this.$store.state.validation = {
				success: true,
				message: '',
				error_code: '',
				data: {}
			}
		},
	}
}
</script>