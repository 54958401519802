<template>
    <v-app>
        <loading :active.sync="loading.isLoading" :can-cancel="false" :is-full-page="loading.fullPage"
            :loader="loading.shape" :height="loading.height" :width="loading.width" :color="loading.color">
        </loading>

        <v-navigation-drawer v-if="(this.$route.name !== 'login' && this.$route.name !== 'registrasi')"
            color="#00E676 !important" app mini-variant mini-variant-width="90" :permanent="clipped">
            <v-list-item class="px-2">
                <v-list-item-avatar>
                    <v-btn class="mx-2" fab small @click="link('profile')">
                        <v-avatar>
                            <v-img :src="user.image"></v-img>
                        </v-avatar>
                    </v-btn>
                </v-list-item-avatar>
            </v-list-item>
            <v-list dense nav>
                <v-list-item v-for="item in menu" :key="item.title">
                    <v-card @click="link(item.path)" width="90" flat height="90" color="#00E676 !important"
                        class="cursor-pointer ma-auto mx-1">
                        <v-card-title class="justify-center pb-1 my-0">
                            <v-icon color="#ffffff" :style="'opacity: 100%'">{{ item.icon }}</v-icon>
                        </v-card-title>
                        <v-card-text class="text-center pa-0 white--text" style="font-size: 10px; opacity: 100%;">
                            {{ item.title }}
                        </v-card-text>
                    </v-card>
                </v-list-item>
                <v-list-item>
                    <v-card @click="logout()" width="90" flat height="90" color="#00E676 !important"
                        class="cursor-pointer ma-auto mx-1">
                        <v-card-title class="justify-center pb-1 my-0">
                            <v-icon color="#ffffff" :style="'opacity: 100%'">mdi-logout</v-icon>
                        </v-card-title>
                        <v-card-text class="text-center pa-0 white--text" style="font-size: 10px; opacity: 100%;">
                            Keluar
                        </v-card-text>
                    </v-card>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar v-if="this.$route.name !== 'login' && this.$route.name !== 'registrasi'" app color="white"
            elevation="0">
            <v-btn class="ma-2" @click="clipped = false" icon fab color="teal"
                v-if="this.$route.name !== 'login' && $vuetify.breakpoint.name == 'xs' && clipped == true">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn class="ma-2" @click="clipped = true" icon fab color="teal"
                v-if="this.$route.name !== 'login' && $vuetify.breakpoint.name == 'xs' && clipped == false">
                <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
            <img src="@/assets/whatsapp-gateway-logo.svg" width="200">
            <v-list-item class="text-right" v-if="$vuetify.breakpoint.name !== 'xs'">
                <v-list-item-content>
                    <v-list-item-title class="text-h6 green--text text--accent-3">
                        {{ user.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="green--text text--accent-3">
                        {{ user.email }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-app-bar>

        <v-main v-if="this.$route.name !== 'login' && this.$route.name !== 'registrasi'">
            <v-tabs color="#00E676" class="mx-3"
                v-if="this.$route.meta.tabs !== undefined && this.$route.meta.tabs.length > 0" left>
                <v-tab v-for="tab in this.$route.meta.tabs" :to="tab.to">{{ tab.name }}</v-tab>
            </v-tabs>
            <v-card elevation="0" style="margin: 1em 2em; height: 100%;">
                <h1 class="mb-4" style="color: rgb(63 230 117);">{{ this.$route.name }}</h1>
                <router-view></router-view>
            </v-card>
        </v-main>

        <router-view v-if="this.$route.name == 'login' || this.$route.name == 'registrasi'"></router-view>
    </v-app>
</template>


<script>
import {
    getToken,
    removeToken
} from '@/service/token.js';
import {
    routes
} from '@/router/index.js';

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    data() {
        return {
            clipped: true,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        menu() {
            return routes.filter((item) => {
                if (item.meta.isOnMenu) {
                    return true
                }
                return false
            }).map((item) => {
                return {
                    title: item.meta.title,
                    icon: item.meta.icon,
                    name: item.name,
                    path: item.path
                }
            })
        },

        loading() {
            return this.$store.state.loading
        },
    },

    methods: {
        link(path) {
            this.clipped = false;
            if (this.$route.path !== path) this.$router.push(path)
        },
        isActive(path) {
            return this.$route.path == path
        },
        logout() {
            this.$swal.fire({
                title: 'Are you sure ?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Yes, Logout!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    removeToken()
                    location.reload()
                    this.$router.push('/login')
                }
            })
        }
    },

    created() {
        // Handling cant back to login and saving user
        if (getToken() !== null && this.$route.name == 'login') {
            this.$router.push('/')
        }
        if (getToken() !== null && this.$route.name == 'registrasi') {
            this.$router.push('/')
        }
        if (getToken() !== null) {
            this.$store.dispatch("getProfile")
        }
    },

    components: {
        Loading
    },
}
</script>

<style>
.active-menu {
    background-color: rgb(63, 230, 117) !important;
}
</style>
